<template>
  <v-footer  padless dark>
    <v-col class="text-center" cols="12">
      <strong><a class="link-site white--text" href="https://acies,guru" target="_blank">ACIES.guru</a></strong> &copy;{{ anno }}
    </v-col>
  </v-footer>
</template>
<script>
export default {
	name: 'Footer',
	computed: {
		anno() {
			return new Date().getFullYear();
		}
	}
}

</script>
<style scoped>
 .link-site {
   text-decoration: none;
 }
</style>
